import React, { useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Divider,
  Form,
  Grid,
  Image,
  Popup,
  Tab,
  Table,
} from 'semantic-ui-react'
import styled from 'styled-components'

import SaveModal from 'components/admin/save-modal'
import HelpMessage from 'components/shared/help-message'
import { UnderlineSubtitle } from 'components/shared/utils'
import ProjectManyProvider from 'context/project/provider-many'
import useProjectFileManyNoContext from 'context/project-file/use-many-no-context'
import useProjectFileMutate from 'context/project-file/use-mutate-no-context'
import { uploadUserProfileImage } from 'context/upload/utils'
import useUserMutateNoContext from 'context/user/use-mutate-no-context'
import { isFORMStaff } from 'context/user/utils'
import Button from 'design-system/components/button'
import { PhoneInput } from 'design-system/components/form'
import { StyledForm } from 'styles/admin/main'
import { FormCheckbox } from 'styles/app/components/checkbox'
import { Breakpoint, Colors } from 'styles/app/system'
import { ProjectFileType } from 'types/project-file'
import {
  FORM_EMPLOYEES,
  User,
  UserType,
  UserTypeHomeowner,
  UserTypePartner,
  UserTypeStaff,
  UserTypeTrade,
} from 'types/user'
import { emailIsValid, getDropdownOptionsFromEnum } from 'views/utils'

import TestimonialForm from './testimonial-form'
import Projects from '../project/index-many'

const UserTab = ({
  profileView,
  user,
  onComplete,
}: {
  profileView?: boolean
  user?: User | null
  onComplete?: () => void
}) => {
  const { projectFiles: testimonials, refetch } = useProjectFileManyNoContext({
    variables: {
      where: {
        user: {
          id: {
            equals: user?.id ?? '',
          },
        },
        type: {
          equals: ProjectFileType.TESTIMONIAL,
        },
      },
      orderBy: { createdAt: 'asc' },
    },
  })
  const { deleteProjectFile } = useProjectFileMutate()
  const { createUser, loadingCreate, loadingUpdate, updateUser } =
    useUserMutateNoContext()

  const initialState: Partial<NullableRecord<User>> = {
    data: {
      ...user?.data,
      inTradeProgram: user?.data?.inTradeProgram || false,
    },
    email: user?.email || '',
    firstName: user?.firstName || '',
    hubspotId: user?.hubspotId || null,
    lastName: user?.lastName || '',
    phone: user?.phone || '',
    picture: user?.picture || '',
    type: user?.type || UserTypeHomeowner.HOMEOWNER,
  }
  const [state, setState] = useState(initialState)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [addTestimonial, setAddTestimonial] = useState(false)

  useEffect(() => {
    setState(initialState)
  }, [user])

  useEffect(() => {
    if (testimonials.length > 0) setAddTestimonial(false)
    else setAddTestimonial(true)
  }, [testimonials])

  const ref = React.createRef<HTMLInputElement>()

  const { calendlyUsers, isAdminOrTrade, isSalesOrTrade, isFormEmployee } =
    useMemo(() => {
      return {
        calendlyUsers: FORM_EMPLOYEES.filter(
          (t) =>
            ![
              UserTypeStaff.TECH_DESIGNER,
              UserTypeStaff.TECH_DESIGNER_SUPPORT,
            ].includes(t as UserTypeStaff),
        ),
        isAdminOrTrade: (
          [UserTypeStaff.ADMIN, UserTypeStaff.TRADE_DESIGNER] as UserType[]
        ).includes(state.type as UserType),
        isSalesOrTrade: (
          [
            UserTypeStaff.ADMIN,
            UserTypeStaff.SALES_DESIGNER,
            UserTypeStaff.TRADE_DESIGNER,
          ] as UserType[]
        ).includes(state.type as UserType),
        isFormEmployee: isFORMStaff(state.type as UserType),
      }
    }, [state.type])

  const onChangeInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [name]: value })

  const onChangeInputNumber = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [name]: parseInt(value) })

  const onChangeData = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setState({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      },
    })

  const onChangeTradeProgam = () => {
    setState({
      ...state,
      data: {
        ...state.data,
        inTradeProgram: !state.data?.inTradeProgram,
      },
    })
  }

  const onChangeInputNumberData = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setState({
      ...state,
      data: {
        ...state.data,
        [name]: parseInt(value),
      },
    })

  const onChangeNotificationPreference = () => {
    setState({
      ...state,
      data: {
        ...state.data,
        subscribedToRenderNotifications:
          !state.data?.subscribedToRenderNotifications,
      },
    })
  }

  const onRemoveTestimonial = async (id: string) => {
    await deleteProjectFile({
      variables: {
        where: {
          id: id,
        },
      },
      onCompleted: () => refetch(),
    })
  }

  const onSave = async () => {
    if (user?.id) {
      await updateUser({
        variables: {
          data: {
            data: state.data,
            email: state.email,
            firstName: state.firstName,
            hubspotId: state.hubspotId,
            lastName: state.lastName,
            phone: state.phone,
            picture: state.picture,
            type: state.type,
          },
          where: {
            id: user?.id,
          },
        },
      })
    } else {
      await createUser({
        variables: {
          data: {
            data: state.data ?? undefined,
            email: state.email ?? '',
            firstName: state.firstName ?? undefined,
            hubspotId: state.hubspotId ?? undefined,
            lastName: state.lastName ?? '',
            phone: state.phone ?? undefined,
            type: state.type ?? undefined,
          },
        },
      })
    }
    onComplete && onComplete()
  }

  return (
    <>
      <StyledTab
        menu={{ secondary: true, pointing: true }}
        panes={[
          {
            menuItem: 'General Information',
            render: () => (
              <Tab.Pane style={{ minHeight: '50vh' }}>
                <StyledForm>
                  <Form.Group widths="3">
                    <div className="flex">
                      {state.picture && !uploadLoading ? (
                        <Image
                          circular
                          src={state.picture}
                          style={{
                            width: '80px',
                            height: '80px',
                            marginBottom: '1rem',
                            marginRight: '1rem',
                          }}
                        />
                      ) : (
                        <ImagePlaceholder>
                          <FontAwesomeIcon size="2x" icon={['fal', 'user']} />
                        </ImagePlaceholder>
                      )}
                      <Button
                        color="gray"
                        disabled={!user}
                        fontAwesomeIcon="upload"
                        kind="ghost"
                        onClick={() => ref.current?.click()}
                        text="Replace profile picture"
                      />
                      <input
                        accept=".jpg,.jpeg,.png,.webp"
                        hidden
                        multiple={false}
                        onChange={async (e) => {
                          setUploadLoading(true)

                          const picture = await uploadUserProfileImage(
                            e.target?.files?.[0] ?? null,
                            user?.id ?? '',
                          )

                          setUploadLoading(false)

                          setState({
                            ...state,
                            picture,
                          })
                        }}
                        ref={ref}
                        type="file"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      label="Fist Name"
                      name="firstName"
                      onChange={onChangeInput}
                      placeholder="First Name"
                      value={state.firstName}
                    />
                    <Form.Input
                      label="Last Name"
                      name="lastName"
                      onChange={onChangeInput}
                      placeholder="Last Name"
                      value={state.lastName}
                    />
                    <Form.Input
                      label="Email"
                      name="email"
                      disabled={!!user}
                      onChange={onChangeInput}
                      required
                      value={state.email}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <PhoneInput
                      label="Phone Number"
                      name="phone"
                      onChange={(value) => {
                        setState({ ...state, phone: value })
                      }}
                      placeholder="Phone Number"
                      value={state.phone ?? ''}
                    />
                    <Form.Select
                      fluid
                      label="User Type"
                      disabled={profileView}
                      onChange={(_, { value }) =>
                        setState({ ...state, type: value as UserType })
                      }
                      options={getDropdownOptionsFromEnum({
                        ...UserTypeStaff,
                        ...UserTypePartner,
                        ...UserTypeHomeowner,
                        ...UserTypeTrade,
                      })}
                      placeholder="Select a user type"
                      required
                      value={state.type ?? undefined}
                    />
                    <Form.Input
                      disabled={
                        !(
                          [
                            UserTypeStaff.ADMIN,
                            UserTypeStaff.SALES_DESIGNER,
                            UserTypeStaff.TRADE_DESIGNER,
                          ] as UserType[]
                        ).includes(state.type as UserType)
                      }
                      label="New Projects Requested"
                      name="newProjectsRequested"
                      placeholder="New Projects Requested"
                      onChange={onChangeInputNumberData}
                      value={state.data?.newProjectsRequested || ''}
                    />
                  </Form.Group>
                  {!profileView &&
                    state.type !== UserTypeHomeowner.HOMEOWNER &&
                    !isFormEmployee && (
                      <>
                        <Divider section />
                        <h4>Trade Program</h4>
                        <FormCheckbox
                          name="inTradeProgram"
                          checked={state.data?.inTradeProgram}
                          label="Subscribed to trade program"
                          onChange={onChangeTradeProgam}
                        />
                      </>
                    )}
                  {isSalesOrTrade && (
                    <>
                      <Divider section />
                      <Form.Input
                        label="Away Message"
                        name="awayMessage"
                        onChange={onChangeData}
                        value={state.data?.awayMessage}
                        width={10}
                      />
                    </>
                  )}
                  {isSalesOrTrade && user ? (
                    <>
                      <Divider section />
                      <Form.Field width={10}>
                        <p>Referral Link</p>
                        <Table celled>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>
                                <CopyLink
                                  id="dashboardLink"
                                  value={`${
                                    process.env.GATSBY_HOME_URL
                                  }/welcome/${
                                    isAdminOrTrade
                                      ? 'trade-onboarding/flow'
                                      : 'free-video-call'
                                  }?designer=${encodeURIComponent(
                                    user.email.split('@')[0],
                                  )}`}
                                />
                              </Table.Cell>
                              <Table.Cell
                                collapsing
                                style={{ background: 'rgba(0,0,0,.03)' }}
                              >
                                <Popup
                                  trigger={
                                    <Button
                                      kind="solid"
                                      fontAwesomeIcon="copy"
                                      onClick={() => {
                                        const copyText =
                                          document.getElementById(
                                            'dashboardLink',
                                          ) as HTMLInputElement
                                        copyText.select()
                                        document.execCommand('copy')
                                      }}
                                    />
                                  }
                                  content="Copied to Clipboard!"
                                  on="click"
                                  hideOnScroll
                                />
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Form.Field>
                    </>
                  ) : null}
                </StyledForm>
              </Tab.Pane>
            ),
          },
          {
            menuItem: {
              key: 'professional',
              content: 'Professional Information',
              disabled: !user,
            },
            render: () => (
              <Tab.Pane style={{ minHeight: '50vh' }}>
                <StyledForm>
                  {isSalesOrTrade && (
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <UnderlineSubtitle>Designer video</UnderlineSubtitle>
                          <Form.Input
                            label="Intro Video URL"
                            name="introVideoUrl"
                            onChange={onChangeData}
                            value={state.data?.introVideoUrl}
                            width={10}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <UnderlineSubtitle>Short bio</UnderlineSubtitle>
                          <Form.TextArea
                            label="Write a short bio (100 characters)"
                            name="shortBio"
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLTextAreaElement>) =>
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  shortBio: value,
                                },
                              })
                            }
                            value={state.data?.shortBio}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      {testimonials.map((f, idx) => (
                        <TestimonialForm
                          index={idx}
                          key={f.id}
                          onRemove={onRemoveTestimonial}
                          refetch={refetch}
                          testimonial={f}
                          userId={user?.id ?? ''}
                        />
                      ))}
                      {addTestimonial && (
                        <TestimonialForm
                          index={testimonials.length}
                          onRemove={onRemoveTestimonial}
                          refetch={refetch}
                          userId={user?.id ?? ''}
                        />
                      )}
                      <Grid.Row>
                        <Grid.Column textAlign="right">
                          <Button
                            fontAwesomeIcon="plus"
                            kind="ghost"
                            onClick={() => setAddTestimonial(true)}
                            style={{ float: 'right' }}
                            text="Add Another Project"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                </StyledForm>
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Calendly',
            render: () => (
              <Tab.Pane>
                <HelpMessage message="Important: Do not touch or change these links unless you are familiar with Calendly setup" />
                <h4>Calendly Setup</h4>
                <StyledForm>
                  <Form.Input
                    disabled={!calendlyUsers.includes(state.type as UserType)}
                    label="Calendly ID"
                    name="calendlyId"
                    onChange={onChangeData}
                    placeholder="Calendly ID (name-formkitchens)"
                    value={state.data?.calendlyId || ''}
                    width={5}
                  />
                  <CustomDivider horizontal section>
                    <p className="subtitle">Sales Designer</p>
                  </CustomDivider>
                  <Form.Group widths="3">
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Initial Call"
                      name="calendlyUrlSlugDesignCall"
                      onChange={onChangeData}
                      placeholder="design-call"
                      value={state.data?.calendlyUrlSlugDesignCall || ''}
                    />
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Design Follow Up"
                      name="calendlyUrlSlugDesignFollowUp"
                      onChange={onChangeData}
                      placeholder="design-follow-up"
                      value={state.data?.calendlyUrlSlugDesignFollowUp || ''}
                    />
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Presentation"
                      name="calendlyUrlSlugPresentation"
                      onChange={onChangeData}
                      placeholder="presentation"
                      value={state.data?.calendlyUrlSlugPresentation || ''}
                    />
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Check In 15"
                      name="calendlyUrlSlugCheckIn15"
                      onChange={onChangeData}
                      placeholder="check-in-15"
                      value={state.data?.calendlyUrlSlugCheckIn15 || ''}
                    />
                  </Form.Group>
                  <Form.Group widths="3">
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Check In 30"
                      name="calendlyUrlSlugCheckIn30"
                      onChange={onChangeData}
                      placeholder="check-in-30"
                      value={state.data?.calendlyUrlSlugCheckIn30 || ''}
                    />
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Check In 60"
                      name="calendlyUrlSlugCheckIn60"
                      onChange={onChangeData}
                      placeholder="check-in-60"
                      value={state.data?.calendlyUrlSlugCheckIn60 || ''}
                    />
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Handover"
                      name="calendlyUrlSlugHandover"
                      onChange={onChangeData}
                      placeholder="handover"
                      value={state.data?.calendlyUrlSlugHandover || ''}
                    />
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Project Consultation"
                      name="calendlyUrlSlugProjectConsultation"
                      onChange={onChangeData}
                      placeholder="project-consultation"
                      value={
                        state.data?.calendlyUrlSlugProjectConsultation || ''
                      }
                    />
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Project Proposal"
                      name="calendlyUrlSlugProjectProposal"
                      onChange={onChangeData}
                      placeholder="project-proposal"
                      value={state.data?.calendlyUrlSlugProjectProposal || ''}
                    />
                  </Form.Group>
                  <CustomDivider horizontal section>
                    <p className="subtitle">Urgent</p>
                  </CustomDivider>
                  <Form.Group widths="2">
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Urgent Initial Call"
                      name="calendlyUrlSlugUrgentDesignCall"
                      onChange={onChangeData}
                      placeholder="design-call-ur"
                      value={state.data?.calendlyUrlSlugUrgentDesignCall || ''}
                    />{' '}
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Urgent Design Follow Up"
                      name="calendlyUrlSlugUrgentDesignFollowUp"
                      onChange={onChangeData}
                      placeholder="design-follow-up-ur"
                      value={
                        state.data?.calendlyUrlSlugUrgentDesignFollowUp || ''
                      }
                    />
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Urgent Presentation"
                      name="calendlyUrlSlugUrgentPresentation"
                      onChange={onChangeData}
                      placeholder="presentation-ur"
                      value={
                        state.data?.calendlyUrlSlugUrgentPresentation || ''
                      }
                    />
                  </Form.Group>
                  <Form.Group widths="3">
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Urgent Check In 15"
                      name="calendlyUrlSlugUrgentCheckIn15"
                      onChange={onChangeData}
                      placeholder="check-in-15-ur"
                      value={state.data?.calendlyUrlSlugUrgentCheckIn15 || ''}
                    />
                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Urgent Check In 30"
                      name="calendlyUrlSlugUrgentCheckIn30"
                      onChange={onChangeData}
                      placeholder="check-in-30-ur"
                      value={state.data?.calendlyUrlSlugUrgentCheckIn30 || ''}
                    />

                    <Form.Input
                      disabled={!calendlyUsers.includes(state.type as UserType)}
                      label="Urgent Check In 60"
                      name="calendlyUrlSlugUrgentCheckIn60"
                      onChange={onChangeData}
                      placeholder="check-in-60-ur"
                      value={state.data?.calendlyUrlSlugUrgentCheckIn60 || ''}
                    />
                  </Form.Group>
                </StyledForm>
              </Tab.Pane>
            ),
          },
          ...(profileView
            ? []
            : [
                {
                  menuItem: 'Rendering',
                  render: () => (
                    <Tab.Pane>
                      <StyledForm>
                        <FormCheckbox
                          disabled={!isFORMStaff(state.type as UserType)}
                          label="Subscribed to rendering notifications (FORM Employees Only)"
                          name="subscribedToRenderNotifications"
                          onChange={onChangeNotificationPreference}
                          checked={
                            !!state.data?.subscribedToRenderNotifications
                          }
                        />
                        <Form.Input
                          disabled={!isFORMStaff(state.type as UserType)}
                          label="Archivizer ID (FORM Employees Only)"
                          name="archId"
                          placeholder="Archivizer ID"
                          onChange={onChangeInputNumberData}
                          value={state.data?.archId || ''}
                        />
                      </StyledForm>
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: 'Integrations',
                  render: () => (
                    <Tab.Pane>
                      <StyledForm>
                        <Form.Input
                          label="Hubspot ID"
                          name="hubspotId"
                          placeholder="Hubspot ID"
                          onChange={onChangeInputNumber}
                          value={state.hubspotId || ''}
                        />
                        <Form.Input
                          disabled={!isFORMStaff(state.type as UserType)}
                          label="Slack ID (FORM Employees Only)"
                          name="slackId"
                          placeholder="Slack ID"
                          onChange={onChangeData}
                          value={state.data?.slackId || ''}
                        />
                        <Form.Input
                          disabled={!isFORMStaff(state.type as UserType)}
                          label="Linear ID (FORM Employees Only)"
                          name="linearId"
                          placeholder="Linear ID"
                          onChange={onChangeData}
                          value={state.data?.linearId || ''}
                        />
                        <Form.Input
                          label="Stripe Customer ID"
                          name="stripeCustomerId"
                          placeholder="Stripe Customer ID"
                          onChange={onChangeData}
                          value={state.data?.stripeCustomerId || ''}
                          disabled={isFormEmployee}
                        />
                      </StyledForm>
                    </Tab.Pane>
                  ),
                },
              ]),
          {
            menuItem: {
              key: 'projects',
              content: 'Projects',
              disabled: !user,
            },
            render: () => (
              <Tab.Pane>
                <ProjectManyProvider
                  defaultFilters={{
                    ownerEmail: user?.email,
                    sortBy: 'derivedActivity',
                    sortDirection: 'descending',
                    status: 'open',
                    take: 30,
                  }}
                  skipLoader
                >
                  <Projects noFilters />
                </ProjectManyProvider>
              </Tab.Pane>
            ),
          },
        ]}
      />
      <SaveModal
        currentState={state}
        initialState={initialState}
        disableSave={!emailIsValid(state.email ?? '') || !state.type}
        loadingSave={loadingCreate || loadingUpdate}
        onDiscard={() => {
          setState(initialState)
        }}
        onSave={onSave}
      />
    </>
  )
}

export default UserTab

const StyledTab = styled(Tab)`
  .tab {
    border: none !important;
  }
`

const ImagePlaceholder = styled.div`
  align-items: center;
  background: ${Colors.gray200};
  border-radius: 50%;
  display: flex;
  height: 80px;
  justify-content: center;
  margin: 0 24px 0 0;
  width: 80px;

  @media ${Breakpoint.downFromSmallMobile} {
    height: 80px;
    width: 80px;
  }
`

const CustomDivider = styled(Divider)`
  ::before {
    width: 0 !important;
  }
  ::after {
    width: 100% !important;
  }
`

const CopyLink = styled.input`
  background: none;
  border: none !important;
  overflow-y: hidden;
  pointer-events: none;
  width: -webkit-fill-available;

  :focus {
    outline: none;
  }
`
