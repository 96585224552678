import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { Grid, Label, Message } from 'semantic-ui-react'
import styled from 'styled-components'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'
import StageAccordion from 'components/admin/project/stage-accordion'
import useProject from 'context/project/use'
import useProjectOverview from 'context/project/use-overview'
import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'
import * as viewUtils from 'views/utils'

const Overview = (_: RouteComponentProps<{ project_id: string }>) => {
  const { project, projectDiscountText, meetingUpcoming } = useProject()
  const overviewStages = useProjectOverview()
  const [showMessage, toggleMessage] = useState<boolean>(true)

  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Overview" />
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={5}>
            <Button
              color="gray"
              fontAwesomeIcon="house-chimney-user"
              kind="outlined"
              onClick={() => window.open(`/app/projects/${project.id}`)}
              text="Go To Customer View"
            />
          </Grid.Column>
          <Grid.Column
            width={11}
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '30px',
              justifyContent: 'end',
            }}
          >
            {projectDiscountText && (
              <DiscountLabel>
                <p
                  dangerouslySetInnerHTML={{
                    __html: projectDiscountText,
                  }}
                />
              </DiscountLabel>
            )}
          </Grid.Column>
        </Grid.Row>
        {meetingUpcoming && showMessage && (
          <Grid.Row>
            <Grid.Column>
              <StyledMessage
                icon={<FontAwesomeIcon icon={['fal', 'video']} />}
                onDismiss={() => toggleMessage(!showMessage)}
                content={`Upcoming ${viewUtils.capsSnakeCaseToTitleCase(
                  meetingUpcoming.metadata.type,
                )} consultation on ${moment(meetingUpcoming.startTime).format(
                  'LLL',
                )}`}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {overviewStages.map((os, idx) => (
          <Grid.Row key={idx}>
            <Grid.Column>
              <StageAccordion overviewStage={os} />
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </ProjectLayout>
  )
}

export default Overview

const DiscountLabel = styled(Label)`
  &&& {
    background: #ecefe6 !important;
    border-radius: 3px;
    border: none;
    padding: 3px 10px;

    p {
      color: #677057;
      font-size: 14px;
    }
  }
`

const StyledMessage = styled(Message)`
  &&& {
    background: ${Colors.yellow50};
    border: 1px solid ${Colors.yellow100};
    border-radius: 8px;
    box-shadow: none;

    p,
    svg,
    i {
      color: ${Colors.yellow800};
    }

    .content {
      margin-left: 20px;
    }
  }
`
