import React from 'react'

import moment from 'moment'

import * as supplierOrderUtils from 'context/supplier-order/utils'
import Button from 'design-system/components/button'
import { StyledLabelAnyColor } from 'styles/admin/main'
import { SupplierOrder } from 'types/supplier-order'

const SupplierOrderCellContentFulfillmentStatus = ({
  onDeliveryClick,
  onRouteClick,
  onShipmentClick,
  supplierOrder,
}: {
  onDeliveryClick?: () => void
  onRouteClick?: () => void
  onShipmentClick?: () => void
  supplierOrder: SupplierOrder
}) => {
  const so = supplierOrder
  const soStatusSummary = supplierOrderUtils.getSupplierOrderStatusSummary(so)
  return (
    <>
      <p className="no-margin">{soStatusSummary.currentStage}</p>
      {soStatusSummary.currentStageDate ? (
        <p className="light-gray x-small">
          {moment(soStatusSummary.currentStageDate).format('MMMM DD, YYYY')}
        </p>
      ) : null}
      <div style={{ display: 'grid', rowGap: '8px' }}>
        {so.shipment && (
          <Button
            color="gray"
            disabled={!onShipmentClick}
            icon={<StyledLabelAnyColor background={so.shipment.color} />}
            iconPosition="left"
            kind="outlined"
            onClick={onShipmentClick}
            text={so.shipment.reference}
          />
        )}
        {so.route && (
          <Button
            color="gray"
            disabled={!onRouteClick}
            icon={<StyledLabelAnyColor background={so.route.color} />}
            iconPosition="left"
            kind="outlined"
            onClick={onRouteClick}
            text={`Route ${so.route.reference}`}
          />
        )}
        {so.delivery && (
          <Button
            color="gray"
            disabled={!onDeliveryClick}
            fontAwesomeIcon="truck"
            iconPosition="left"
            kind="outlined"
            onClick={onDeliveryClick}
            text={so.delivery.data?.name || 'Delivery'}
          />
        )}
      </div>
    </>
  )
}

export default SupplierOrderCellContentFulfillmentStatus
