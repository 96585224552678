import React, { useEffect, useState } from 'react'

import moment from 'moment'
import TextareaAutosize from 'react-textarea-autosize'
import { DateInput } from 'semantic-ui-calendar-react'
import { DropdownProps, Form, Message } from 'semantic-ui-react'
import styled from 'styled-components'

import CurrencyInput from 'components/shared/currency-input'
import { formatDollars } from 'context/dollar-utils'
import {
  getDefaultPayment,
  PaymentTypeOptionsTrade,
  PaymentTypeOptionsHomeowner,
  PaymentMethodTypeOptions,
  PaymentStatusOptions,
} from 'context/payment/utils'
import useProject from 'context/project/use'
import useProjectPaymentMutate from 'context/project/use-payment-mutate'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { Payment, PaymentType, PaymentMethodType } from 'types/payment'
import { capsSnakeCaseToTitleCase } from 'views/utils'

interface PaymentCreateUpdateModalProps {
  payment?: Payment
  open?: boolean
  onClose: () => void
}

const PaymentCreateUpdateModal = ({
  payment,
  open,
  onClose,
}: PaymentCreateUpdateModalProps) => {
  const { isTradeProject, project } = useProject()
  const {
    createPaymentAndRefetch,
    loadingCreate,
    loadingUpdate,
    updatePaymentAndRefetch,
  } = useProjectPaymentMutate()

  const getDefaultState = () =>
    getDefaultPayment(PaymentType.DESIGN_DEPOSIT, isTradeProject)

  const [state, setState] = useState<Payment>(payment || getDefaultState())

  useEffect(() => {
    setState(payment || getDefaultState())
  }, [payment, open])

  const onChangePrice = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [name]: Math.round(Number(value.replace(/[, $]+/g, '')) * 100),
    })
  }

  const onChangeRefundAmount = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [name]: Math.round(Number(value.replace(/[, $]+/g, '')) * 100),
    })
  }

  const onChangeStatus = (
    _: React.SyntheticEvent,
    { value }: DropdownProps,
  ) => {
    setState({
      ...state,
      status: value as 'succeeded' | 'pending' | 'failed',
    })
  }

  const onChangeType = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => {
    const v = value as PaymentType
    const {
      amount,
      metadata: { name },
    } = getDefaultPayment(v, isTradeProject)

    setState({
      ...state,
      amount: amount,
      metadata: {
        ...state.metadata,
        type: value as PaymentType,
        name,
      },
    })
  }

  const onChangePaymentType = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => {
    setState({
      ...state,
      metadata: {
        ...state.metadata,
        method: value === '' ? undefined : (value as PaymentMethodType),
      },
    })
  }

  const onChangeName = (
    _: React.SyntheticEvent,
    { value }: { value: string },
  ) => {
    setState({
      ...state,
      metadata: {
        ...state.metadata,
        name: value,
      },
    })
  }

  const onChangeRefundNote = (e: React.ChangeEvent<HTMLInputElement>) =>
    setState({
      ...state,
      metadata: {
        ...state.metadata,
        refundNote: e.target.value,
      },
    })

  const onChangeStripeChargeId = (
    _: React.SyntheticEvent,
    { value }: { value: string },
  ) => {
    setState({
      ...state,
      stripeChargeId: value,
    })
  }

  const onChangeStripeInvoiceId = (
    _: React.SyntheticEvent,
    { value }: { value: string },
  ) => {
    setState({
      ...state,
      stripeInvoiceId: value,
    })
  }

  const onSave = async () => {
    if (payment)
      await updatePaymentAndRefetch({
        variables: {
          where: { id: payment.id ?? '' },
          data: {
            amount: state.amount,
            description: capsSnakeCaseToTitleCase(state.metadata.type),
            metadata: state.metadata,
            refundAmount: state.refundAmount,
            status: state.status,
            stripeChargeId: state.stripeChargeId,
            stripeInvoiceId: state.stripeInvoiceId,
          },
        },
      })
    else
      await createPaymentAndRefetch({
        variables: {
          data: {
            amount: state.amount,
            description: capsSnakeCaseToTitleCase(state.metadata.type),
            metadata: state.metadata,
            project: {
              connect: { id: project.id },
            },
            refundAmount: state.refundAmount,
            status: state.status,
          },
        },
      })
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="medium"
      title={payment ? 'Edit Payment' : 'Create Payment'}
      saveButton={{
        disabled:
          !!state.metadata?.name &&
          !!state?.metadata.type &&
          state.amount === 0,
        loading: loadingCreate || loadingUpdate,
        onClick: onSave,
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Field>
          <label>Type</label>
          <Form.Dropdown
            name="type"
            onChange={onChangeType}
            options={
              isTradeProject
                ? PaymentTypeOptionsTrade
                : PaymentTypeOptionsHomeowner
            }
            placeholder="Payment Type"
            selection
            value={state.metadata.type}
          />
        </Form.Field>
        <Form.Field>
          <label>Method</label>
          <Form.Dropdown
            name="type"
            onChange={onChangePaymentType}
            options={PaymentMethodTypeOptions}
            placeholder="Payment Type"
            selection
            value={state.metadata.method || ''}
          />
        </Form.Field>
        <Form.Field required>
          <label>Name</label>
          <Form.Input
            name="name"
            type="text"
            onChange={onChangeName}
            value={state.metadata.name}
          />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Amount</label>
            <CurrencyInput
              defaultValue={formatDollars(state.amount)}
              inputMode="numeric"
              maskOptions={{}}
              name="amount"
              onChange={onChangePrice}
              value={formatDollars(state.amount)}
              placeholder="$299.00"
            />
          </Form.Field>
        </Form.Group>
        {payment ? (
          <Form.Group widths="equal">
            <Form.Field>
              <label>Refund Amount</label>
              <CurrencyInput
                defaultValue={formatDollars(state.refundAmount)}
                inputMode="numeric"
                maskOptions={{}}
                name="refundAmount"
                onChange={onChangeRefundAmount}
                value={formatDollars(state.refundAmount)}
              />
            </Form.Field>
            <Form.Field>
              <label>Refund Date</label>
              <StyledDateInputContainer>
                <DateInput
                  closable
                  dateFormat={'MM/DD/YYYY'}
                  duration={0}
                  initialDate={moment().format('MM/DD/YYYY')}
                  minDate={moment().subtract(2, 'years').format('MM/DD/YYYY')}
                  name="date"
                  onChange={(
                    _: React.SyntheticEvent<HTMLElement, Event>,
                    { value }: { value: string },
                  ) => {
                    setState({
                      ...state,
                      metadata: {
                        ...state.metadata,
                        refundDate: value,
                      },
                    })
                  }}
                  value={state.metadata?.refundDate || ''}
                />
              </StyledDateInputContainer>
            </Form.Field>
          </Form.Group>
        ) : null}
        {payment ? (
          <StyledFormField
            id="refundNote"
            control={TextareaAutosize}
            label="Refund Note"
            name="refundNote"
            onChange={onChangeRefundNote}
            value={state.metadata.refundNote || ''}
          />
        ) : null}
      </StyledForm>
      {payment ? (
        <Message>
          <p className="small">
            <i>System fields, do not touch except in special cases</i>
          </p>
          <StyledForm>
            <Form.Field>
              <label>Status</label>
              <Form.Dropdown
                name="type"
                onChange={onChangeStatus}
                options={PaymentStatusOptions}
                placeholder="Payment Status"
                selection
                value={state.status}
              />
            </Form.Field>
            <Form.Field>
              <label>Paid At</label>
              <StyledDateInputContainer>
                <DateInput
                  closable
                  dateFormat={'MM/DD/YYYY'}
                  duration={0}
                  initialDate={moment().format('MM/DD/YYYY')}
                  name="date"
                  onChange={(
                    _: React.SyntheticEvent<HTMLElement, Event>,
                    { value }: { value: string },
                  ) => {
                    setState({
                      ...state,
                      metadata: {
                        ...state.metadata,
                        paidAt: value
                          ? moment(value, 'MM/DD/YYYY').format()
                          : '',
                      },
                    })
                  }}
                  value={
                    state.metadata?.paidAt
                      ? moment(state.metadata?.paidAt).format('MM/DD/YYYY')
                      : ''
                  }
                />
              </StyledDateInputContainer>
            </Form.Field>
            <Form.Field>
              <label>Stripe Charge ID</label>
              <Form.Input
                name="stripeChargeId"
                onChange={onChangeStripeChargeId}
                value={state.stripeChargeId || ''}
              />
            </Form.Field>
            <Form.Field>
              <label>Stripe Invoice ID</label>
              <Form.Input
                name="stripeInvoiceId"
                onChange={onChangeStripeInvoiceId}
                value={state.stripeInvoiceId || ''}
              />
            </Form.Field>
          </StyledForm>
        </Message>
      ) : null}
    </Modal>
  )
}

const StyledFormField = styled(Form.Field)`
  textarea {
    min-height: 50px !important;
  }
`

const StyledDateInputContainer = styled.div`
  &&& {
    thead {
      text-align: center !important;
    }
  }
`

export default PaymentCreateUpdateModal
