import React, { useState } from 'react'

import { Form } from 'semantic-ui-react'

import { notifyAppError } from 'context/notifications/trigger'
import useProject from 'context/project/use'
import useProjectUserMutate from 'context/project/use-project-user-mutate'
import useUserMutateNoContext from 'context/user/use-mutate-no-context'
import useUniqueUser from 'context/user/use-unique'
import { PhoneInput, validatePhone } from 'design-system/components/form'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox, StyledForm } from 'styles/admin/main'
import {
  ProjectUserRole,
  ProjectUserRoleHomeowner,
  ProjectUserRoleTrade,
} from 'types/project-user'
import { emailIsValid, getDropdownOptionsFromEnum } from 'views/utils'

type Props = {
  onClose: () => void
  open: boolean
}

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  type: ProjectUserRoleHomeowner.HOMEOWNER as ProjectUserRole,
  isPrimary: false,
  isSubscribed: true,
}

const CreateNewProjectUserModal = ({ onClose, open }: Props) => {
  const { project, isTradeProject } = useProject()
  const { createProjectUserAndRefetch, loadingCreate } = useProjectUserMutate()
  const { createUser, loadingCreate: loadingCreateUser } =
    useUserMutateNoContext()

  const [state, setState] = useState(initialState)
  const [phoneError, setPhoneError] = useState<boolean>(false)

  const { user: uniqueUser } = useUniqueUser({ search: state.email })

  const onSave = async () => {
    const { isPrimary, isSubscribed, ...userData } = state

    if (uniqueUser) {
      onClose()
      notifyAppError('User already exists. Please use the "Attach" button.')
      return
    }

    const res = await createUser({
      variables: { data: userData },
    })

    const newUser = res.data?.createOneUser

    await createProjectUserAndRefetch({
      variables: {
        data: {
          project: {
            connect: {
              id: project.id,
            },
          },
          user: {
            connect: {
              id: newUser?.id ?? '',
            },
          },
          isPrimary: isPrimary,
          isSubscribed: isSubscribed,
          userRole: userData.type,
        },
      },
    })

    onClose()
  }

  const onInputChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      onExited={() => {
        setState(initialState)
        setPhoneError(false)
      }}
      size="small"
      title="Create User and Add To Project"
      disableEscape
      disableBackdropClick
      saveButton={{
        loading: loadingCreate || loadingCreateUser,
        onClick: onSave,
        disabled:
          !emailIsValid(state.email) ||
          !state.firstName ||
          !state.lastName ||
          !validatePhone(state.phone),
      }}
    >
      <StyledForm>
        <Form.Group widths="equal">
          <Form.Input
            required
            label="First Name"
            name="firstName"
            onChange={onInputChange}
            placeholder="First Name"
            value={state.firstName}
          />
          <Form.Input
            required
            label="Last Name"
            name="lastName"
            onChange={onInputChange}
            placeholder="Last Name"
            value={state.lastName}
          />
        </Form.Group>
        <Form.Input
          required
          label="Email"
          name="email"
          onChange={onInputChange}
          placeholder="Email"
          value={state.email}
          autoComplete="off"
        />
        <PhoneInput
          {...(phoneError && {
            error: {
              content: (
                <p className="red small">Please enter a valid phone number</p>
              ),
            },
          })}
          required
          label="Phone"
          name="phone"
          onBlur={() => setPhoneError(!validatePhone(state.phone))}
          onChange={(value) => {
            setState({ ...state, phone: value })
            if (phoneError) setPhoneError(!validatePhone(state.phone))
          }}
          value={state.phone}
          placeholder="Phone"
        />
        <Form.Dropdown
          fluid
          required
          label="Role"
          options={getDropdownOptionsFromEnum(
            isTradeProject
              ? { ...ProjectUserRoleTrade, ...ProjectUserRoleHomeowner }
              : ProjectUserRoleHomeowner,
          )}
          onChange={(_, { value }) =>
            setState({
              ...state,
              type: value as ProjectUserRole,
            })
          }
          placeholder="Role"
          selection
          value={state.type}
        />
        <FormCheckbox
          checked={state.isPrimary}
          label="Primary"
          onChange={() =>
            setState({
              ...state,
              isPrimary: !state.isPrimary,
            })
          }
        />
        <FormCheckbox
          checked={state.isSubscribed}
          label="Subscribed"
          onChange={() =>
            setState({
              ...state,
              isSubscribed: !state.isSubscribed,
            })
          }
        />
      </StyledForm>
    </Modal>
  )
}

export default CreateNewProjectUserModal
