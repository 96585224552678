import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormCheckboxValue,
  IFormRadioValue,
} from 'components/admin/filters-search'
import useCatalogFeatureMany from 'context/catalog-feature/use-many'
import { camelCaseToTitleCase } from 'views/utils'

export type CatalogFeatureFilterValues = {
  showingOnCatalog: IFormRadioValue
  groupIdentifiers: IFormCheckboxValue
}

const useCatalogFeatureFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useCatalogFeatureMany()

  const filters: DynamicFilterGroup<CatalogFeatureFilterValues>[] =
    useMemo(() => {
      return [
        {
          filters: [
            {
              filterId: 'showingOnCatalog',
              type: DynamicFilterTypes.RADIO,
              label: 'Showing on catalog',
              options: [
                {
                  text: 'All',
                  value: 'all',
                },
                {
                  text: 'Showing On Catalog',
                  value: 'showingOnCatalog',
                },
              ],
            },
          ],
        },
        {
          filters: [
            {
              filterId: 'groupIdentifiers',
              type: DynamicFilterTypes.CHECKBOX,
              label: 'Group',
              options: groupOptions.map((go) => {
                return {
                  key: go.groupIdentifier,
                  text: go.label,
                  value: go.groupIdentifier,
                }
              }),
            },
          ],
        },
      ]
    }, [])

  const defaultFilters = useMemo(() => {
    const catalogFeatureFilters: Partial<CatalogFeatureFilterValues> = {}

    if (queryFilters?.showingOnCatalog)
      catalogFeatureFilters.showingOnCatalog = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.showingOnCatalog,
          text: camelCaseToTitleCase(queryFilters.showingOnCatalog),
        },
      }
    if (queryFilters?.groupIdentifiers?.length)
      catalogFeatureFilters.groupIdentifiers = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: queryFilters.groupIdentifiers.map((s) => {
          return {
            value: s,
            text: camelCaseToTitleCase(s),
          }
        }),
      }
    return catalogFeatureFilters
  }, [queryFilters])

  const handleApplyFilters = (data: CatalogFeatureFilterValues) => {
    setQueryFilters({
      showingOnCatalog: data.showingOnCatalog?.selection.value as
        | 'all'
        | 'showingOnCatalog',
      groupIdentifiers: data.groupIdentifiers
        ? (data.groupIdentifiers.selection.map((f) => f.value) as string[])
        : [],
      sortBy: queryFilters?.sortBy ?? 'identifier',
      sortDirection: queryFilters?.sortDirection ?? 'ascending',
      take: queryFilters?.take ?? 0,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...(currQueryFilters as any),
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useCatalogFeatureFiltersSearch

export const groupOptions = [
  {
    groupIdentifier: 'baseUnitCabinets',
    label: 'Base Unit Cabinets',
  },
  {
    groupIdentifier: 'baseUnitDrawers',
    label: 'Base Unit Drawers',
  },
  {
    groupIdentifier: 'baseUnitPullouts',
    label: 'Base Unit Pullouts',
  },
  {
    groupIdentifier: 'bathroom',
    label: 'Bathroom',
  },
  {
    groupIdentifier: 'cornerStorage',
    label: 'Corner Storage',
  },
  {
    groupIdentifier: 'countertopAndBacksplash',
    label: 'Countertop and Backsplash',
  },
  {
    groupIdentifier: 'drawerInserts',
    label: 'Drawer Inserts',
  },
  {
    groupIdentifier: 'integratedLighting',
    label: 'Integrated Lighting',
  },
  {
    groupIdentifier: 'lineNFeatures',
    label: 'Line N Features',
  },
  {
    groupIdentifier: 'shelvingUnits',
    label: 'Shelving Units',
  },
  {
    groupIdentifier: 'signEmotion',
    label: 'Sign Emotion',
  },
  {
    groupIdentifier: 'tallUnits',
    label: 'Tall Units',
  },
  {
    groupIdentifier: 'wallUnits',
    label: 'Wall Units',
  },
  {
    groupIdentifier: 'washbasins',
    label: 'Washbasins',
  },
]
